<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">二级单位用户管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入单位名称" class="iw"></el-input>
                <el-input v-model="lname" placeholder="请输入登录名" class="iw"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="EJDW" label="二级单位名称"></el-table-column>
                    <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                            <el-button @click="resetPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">密码重置</el-button>
                            <!-- <el-button @click="pwdPost(scope.row)" type="text" size="small" style="color:#E6A23C">修改密码</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                    :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px"
                class="demo-uForm">
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="postInfo.ejdw" placeholder="请选择二级单位" filterable style="width:100%;">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" prop="disPlayName">
                    <el-input v-model="postInfo.displayName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginName">
                    <el-input v-model="postInfo.loginName" autocomplete="off" :disabled="doType == '2'"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType == '1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="修改密码" :visible.sync="pwdModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="pwdInfo" status-icon :rules="pwdrules" ref="pForm" label-width="100px" class="demo-uForm">
                <el-form-item label="旧密码" prop="oldPassWord">
                    <el-input v-model="pwdInfo.oldPassWord" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassWord">
                    <el-input v-model="pwdInfo.newPassWord" autocomplete="off" :disabled="doType == '2'"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPwdForm()">提交</el-button>
                    <el-button @click="pwdModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "OilUserManage",
    data() {

        var ejdw = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请选择二级单位"));
            } else {
                callback();
            }
        };

        var displayName = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入用户姓名"));
            } else {
                callback();
            }
        };
        var loginName = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入登录名"));
            } else {
                callback();
            }
        };
        var password = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入密码"));
            } else {
                callback();
            }
        };
        var phone = (rule, value, callback) => {
            if (value != '' && !/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };

        var oldPassWord = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入旧密码"));
            } else {
                callback();
            }
        };

        var newPassWord = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入新密码"));
            } else {
                callback();
            }
        };


        return {

            canDo: true,
            showPagination: false,
            name: "",
            lname: "",

            ejdwList: [],

            postList: [],
            pageIndex: 1,
            pageSize: 10,
            Total: 0,


            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {

            },
            postrules: {
                ejdw: [{ validator: ejdw, trigger: "blur" }],
                displayName: [{ validator: displayName, trigger: "blur" }],
                loginName: [{ validator: loginName, trigger: "blur" }],
                phone: [{ validator: phone, trigger: "blur" }],
                password: [{ validator: password, trigger: "blur" }],
            },

            pwdModal: false,
            pwdInfo: {
                sysUserSnowID: "",
                oldPassWord: "",
                newPassWord: "",
            },
            pwdrules: {
                oldPassWord: [{ validator: oldPassWord, trigger: "blur" }],
                newPassWord: [{ validator: newPassWord, trigger: "blur" }],
            },

            nowResetPwdStr: "",
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.pageIndex = getQueryData.pageIndex
            this.name = getQueryData.name
            this.lname = getQueryData.loginName
        }
        this.loadItem = null
        this.getEjdwList()
        this.getPostList()
    },
    methods: {
        getEjdwList() {
            this.$http.get("Land/GetEJDWList", {})
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.ejdwList = response.data.DATA
                    }
                })
        },
        pwdPost(item) {
            this.pwdInfo = {
                sysUserSnowID: item.SNOWID,
                oldPassWord: "",
                newPassWord: "",
            }
            this.pwdModal = true
        },

        submitPwdForm() {
            if (this.canDo) {
                this.$refs.pForm.validate((valid) => {
                    if (valid) {
                        this.sendPwd()
                    } else {
                        return false;
                    }
                });
            }
        },

        sendPwd() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/UpdatePassWord", this.pwdInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.pwdModal = false
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },

        getPostList() {
            this.showLoading("请稍后")
            var params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                name: this.name,
                loginName: this.lname,
            }
            this.setQueryData(this.$route.name, params)
            this.$http.post("CheckEJDW/GetCheckEJDW", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        searchPost() {
            this.pageIndex = 1
            this.getPostList()
        },
        PGChange(e) {
            this.pageIndex = e
            this.getPostList()
        },

        addPost() {
            this.postInfo = {
                displayName: "",
                loginName: "",
                phone: "",
                password: "",
                ejdw: "",
            }
            this.doTypeStr = "添加二级单位用户"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("CheckEJDW/InsertData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.getPostList()
                        this.postModal = false
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        editPost(item) {
            this.postInfo = {
                snowID: item.SNOWID,
                displayName: item.DISPLAYNAME,
                loginName: item.LOGINNAME,
                phone: item.PHONE,
                password: item.PASSWORD,
                ejdw: item.EJDW,
            }
            this.doTypeStr = "修改二级单位用户"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("CheckEJDW/UpdateData", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },

        delPost(item) {
            if (this.canDo) {
                this.$confirm('确定删除单位：' + item.DISPLAYNAME + '？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sendDel(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }
        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("CheckEJDW/DeleteData", { snowid: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        resetPost(item) {
            if (this.canDo) {
                this.$confirm('确定将：' + item.DISPLAYNAME + '的密码重置？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.nowResetPwdStr = this.getNewPasswordStr()
                    this.sendReset(item.SNOWID)
                }).catch(() => {
                    this.$message.info("已取消")
                });
            }

        },
        sendReset(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/ReSetPassWord", { sysUserSnowID: id, newPassWord: this.nowResetPwdStr })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$confirm('新密码：' + this.nowResetPwdStr, '密码已重置', {
                            confirmButtonText: '复制',
                            cancelButtonText: '关闭',
                            type: 'warning',
                            showClose: false,
                            closeOnClickModal: false,
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    const textToCopy = this.nowResetPwdStr;
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(textToCopy)
                                            .then(() => {
                                                this.$message.success('复制成功！');
                                            })
                                            .catch(err => {
                                                console.error('复制失败:', err);
                                                this.$message.error('复制失败，请重试');
                                            });
                                    } else {
                                        const textarea = document.createElement('textarea');
                                        textarea.value = textToCopy;
                                        textarea.style.position = 'fixed';
                                        textarea.style.opacity = '0';
                                        document.body.appendChild(textarea);
                                        textarea.select();
                                        if (document.execCommand('copy')) {
                                            this.$message.success('复制成功！');
                                        } else {
                                            this.$message.error('复制失败，请手动复制');
                                        }
                                        document.body.removeChild(textarea);
                                    }
                                } else {
                                    done();
                                }
                            }
                        }).then(() => {
                            //
                        }).catch(() => {
                            //
                        });
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        getNewPasswordStr() {
            const lowercase = 'abcdefghijklmnopqrstuvwxyz';
            const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const specialChars = '.@$!%*?&';
            let result = [
                lowercase[Math.floor(Math.random() * lowercase.length)],
                uppercase[Math.floor(Math.random() * uppercase.length)],
                numbers[Math.floor(Math.random() * numbers.length)],
                specialChars[Math.floor(Math.random() * specialChars.length)]
            ];
            const allChars = lowercase + uppercase + numbers + specialChars;
            for (let i = 0; i < 4; i++) {
                result.push(allChars[Math.floor(Math.random() * allChars.length)]);
            }
            result.sort(() => Math.random() - 0.5);
            return result.join('');
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped></style>