<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">平台管理员</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="tname" placeholder="请输入姓名" class="iw"></el-input>
                <el-input v-model="lname" placeholder="请输入登录名" class="iw"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="DISPLAYNAME" label="姓名"></el-table-column>
                    <el-table-column prop="PHONE" label="手机号"></el-table-column>
                    <el-table-column prop="LOGINNAME" label="登录名"></el-table-column>
                    <el-table-column prop="UserRoleStr" label="登录权限"></el-table-column>
                    <!-- <el-table-column prop="CREATETIME" label="创建时间"></el-table-column> -->
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)" type="text" size="small"
                                style="color:#F56C6C">删除</el-button>
                            <el-button @click="resetPost(scope.row)" type="text" size="small"
                                style="color:#E6A23C">密码重置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                    :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="800px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules" ref="uForm" label-width="100px"
                class="demo-uForm">
                <el-form-item label="姓名" prop="displayname">
                    <el-input v-model="postInfo.displayname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="postInfo.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录名" prop="loginname">
                    <el-input v-model="postInfo.loginname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" v-if="doType == '1'">
                    <el-input v-model="postInfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录权限">
                    <el-checkbox-group v-model="roleList">
                        <el-checkbox label="后台" style="margin-right:16px;"></el-checkbox>
                        <el-checkbox label="任务大屏" style="margin-right:16px;"></el-checkbox>
                        <el-checkbox label="清查大屏" style="margin-right:16px;"></el-checkbox>
                        <el-checkbox label="H5" style="margin-right:16px;"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "PostManage",
    data() {
        var displayname = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入姓名"));
            } else {
                callback();
            }
        };
        var loginname = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入登录名"));
            } else {
                callback();
            }
        };
        var password = (rule, value, callback) => {
            if (value === "" || value == null) {
                callback(new Error("请输入密码"));
            } else {
                callback();
            }
        };
        var phone = (rule, value, callback) => {
            if (value === '' || value == null) {
                callback(new Error('请输入手机号'));
            } else if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };

        return {
            canDo: true,
            showPagination: false,
            lname: "",
            tname: "",

            postList: [],
            PageIndex: 1,
            PageSize: 10,
            Total: 0,

            doTypeStr: "",
            doType: "",
            postModal: false,
            postInfo: {},
            postrules: {
                loginname: [{ validator: loginname, trigger: "blur" }],
                password: [{ validator: password, trigger: "blur" }],
                displayname: [{ validator: displayname, trigger: "blur" }],
                phone: [{ validator: phone, trigger: "blur" }],
            },
            roleList: [],

            nowResetPwdStr: "",
        };
    },
    mounted() {
        var getQueryData = this.getQueryData(this.$route.name)
        if (getQueryData != undefined) {
            this.pageIndex = getQueryData.pageIndex
            this.lname = getQueryData.loginName
            this.tname = getQueryData.disPlayName
        }
        this.loadItem = null
        this.getPostList()
    },
    methods: {
        getPostList() {
            this.showLoading("请稍后")
            var params = {
                PageIndex: this.PageIndex,
                PageSize: this.PageSize,
                loginName: this.lname,
                disPlayName: this.tname,
            }
            this.setQueryData(this.$route.name, params)
            this.$http.post("SysUser/GetSysUserList", params)
                .then((response) => {
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.postList = response.data.DATA
                        this.Total = response.data.TOTALCOUNT
                    } else {
                        this.postList = []
                        this.Total = 0
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                    this.hideLoading()
                    this.postList = []
                    this.Total = 0
                });
        },
        searchPost() {
            this.PageIndex = 1
            this.getPostList()
        },
        PGChange(e) {
            this.PageIndex = e
            this.getPostList()
        },
        addPost() {
            this.postInfo = {
                loginname: "",
                password: "",
                displayname: "",
                phone: "",
            }
            this.roleList = []
            this.doTypeStr = "添加管理员"
            this.doType = "1"
            this.postModal = true
        },
        sendAdd() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/InsertSysUser", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已添加")
                        this.getPostList()
                        this.postModal = false
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        editPost(item) {
            this.postInfo = {
                SnowID: item.SNOWID,
                loginname: item.LOGINNAME,
                displayname: item.DISPLAYNAME,
                phone: item.PHONE
            }
            var rl = []
            if (item.UserRoleStr && item.UserRoleStr != '') {
                if (item.UserRoleStr.indexOf(',') != -1) {
                    rl = item.UserRoleStr.split(",")
                } else {
                    rl.push(item.UserRoleStr)
                }
            }
            this.roleList = rl
            this.doTypeStr = "修改管理员"
            this.doType = "2"
            this.postModal = true
        },
        sendEdit() {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/UpdateSysUser", this.postInfo)
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已修改")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },

        submitPostForm() {
            if (this.canDo) {
                this.$refs.uForm.validate((valid) => {
                    if (valid) {
                        var str = ''
                        for (var i = 0; i < this.roleList.length; i++) {
                            if (i == 0) {
                                str = this.roleList[i]
                            } else {
                                str += "," + this.roleList[i]
                            }
                        }
                        this.postInfo.RoleStr = str
                        if (this.doType == "1") {
                            this.sendAdd()
                        } else if (this.doType == "2") {
                            this.sendEdit()
                        }
                    } else {
                        return false;
                    }
                });
            }
        },
        resetPost(item) {
            if (this.canDo) {
                this.$confirm('确定将：'+item.DISPLAYNAME+'的密码重置？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.nowResetPwdStr=this.getNewPasswordStr()
                        this.sendReset(item.SNOWID)
                    }).catch(() => {
                        this.$message.info("已取消")
                    });
            }

        },
        sendReset(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/ReSetPassWord", { sysUserSnowID: id, newPassWord: this.nowResetPwdStr })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$confirm('新密码：' + this.nowResetPwdStr, '密码已重置', {
                            confirmButtonText: '复制',
                            cancelButtonText: '关闭',
                            type: 'warning',
                            showClose: false,
                            closeOnClickModal: false,
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    const textToCopy = this.nowResetPwdStr;
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(textToCopy)
                                            .then(() => {
                                                this.$message.success('复制成功！');
                                            })
                                            .catch(err => {
                                                console.error('复制失败:', err);
                                                this.$message.error('复制失败，请重试');
                                            });
                                    } else {
                                        const textarea = document.createElement('textarea');
                                        textarea.value = textToCopy;
                                        textarea.style.position = 'fixed';
                                        textarea.style.opacity = '0';
                                        document.body.appendChild(textarea);
                                        textarea.select();
                                        if (document.execCommand('copy')) {
                                            this.$message.success('复制成功！');
                                        } else {
                                            this.$message.error('复制失败，请手动复制');
                                        }
                                        document.body.removeChild(textarea);
                                    }
                                } else {
                                    done();
                                }
                            }
                        }).then(() => {
                            //
                        }).catch(() => {
                            //
                        });
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
        getNewPasswordStr() {
            const lowercase = 'abcdefghijklmnopqrstuvwxyz';
            const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const numbers = '0123456789';
            const specialChars = '.@$!%*?&';
            let result = [
                lowercase[Math.floor(Math.random() * lowercase.length)],
                uppercase[Math.floor(Math.random() * uppercase.length)],
                numbers[Math.floor(Math.random() * numbers.length)],
                specialChars[Math.floor(Math.random() * specialChars.length)]
            ];
            const allChars = lowercase + uppercase + numbers + specialChars;
            for (let i = 0; i < 4; i++) {
                result.push(allChars[Math.floor(Math.random() * allChars.length)]);
            }
            result.sort(() => Math.random() - 0.5);
            return result.join('');
        },

        delPost(item) {
            if (this.canDo) {
                if (item.SNOWID == this.$store.state.snowid) {
                    this.$message.error("不能删除当前登录账号")
                } else {
                    this.$confirm('确定删除管理员：' + item.DISPLAYNAME + '？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.sendDel(item.SNOWID)
                    }).catch(() => {
                        this.$message.info("已取消")
                    });
                }
            }
        },
        sendDel(id) {
            this.canDo = false
            this.showLoading("请稍后")
            this.$http.post("SysUser/DeleteSysUser", { SnowID: id })
                .then((response) => {
                    this.canDo = true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.$message.success("已删除")
                        this.postModal = false
                        this.getPostList()
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo = true
                    if (error.isAxiosError) {
                        if (error.response.status == 401) {
                            this.logout()
                        }
                    }
                });
        },
    },
    computed: {
    },
    watch: {
    },
};
</script>
<style scoped>
.addProTtem {
    float: right;
}

.showFieldTtem {
    float: right;
    margin-right: 20px;
}

.itemOut {
    overflow: hidden;
    background-color: #F8F8F9;
    padding: 10px 20px 0 20px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.halfItem {
    width: 50%;
    float: left;
    margin-bottom: 25px !important;
}

.itemBox {
    overflow: hidden;
    width: 100%;
}

.itemTit {
    overflow: hidden;
}

.itemTit p {
    float: left;
}

.moreRow {
    background-color: #F8F8F9;
    overflow: hidden;
    padding: 12px;
}

.moreRow .moreRowTit {
    margin: 10px 0;
    font-size: 16px;
}

.moreRow .moreRowTable {
    width: 100%;
}
</style>