<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">分公司/勘探局数据管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="shpType" placeholder="选择权利人" filterable class="iw">
                    <el-option label="分公司" value="1"></el-option>
                    <el-option label="勘探局" value="2"></el-option>
                </el-select>
                <el-select v-model="attributeName" placeholder="选择查询字段" filterable class="iw">
                    <el-option label="ID" value="Id"></el-option>
                    <el-option label="名称" value="mc"></el-option>
                    <el-option label="宗地名称" value="zdmc"></el-option>
                    <el-option label="档案号" value="dah"></el-option>
                    <el-option label="不动产权证" value="bdcqz"></el-option>
                    <el-option label="宗地代码" value="zddm"></el-option>
                    <el-option label="坐落" value="zl"></el-option>
                    <el-option label="土地证号" value="tdzh"></el-option>
                    <el-option label="土地批复号" value="tdpfh"></el-option>
                    <el-option label="权利类型" value="qllx"></el-option>
                    <el-option label="权利性质" value="qlxz"></el-option>
                    <el-option label="用途" value="yt"></el-option>
                </el-select>
                <el-input v-model="searchContent" placeholder="请输入查询内容" clearable class="iw"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="Id" label="ID"></el-table-column>
                    <el-table-column prop="mc" label="名称"></el-table-column>
                    <el-table-column prop="zdmc" label="宗地名称"></el-table-column>
                    <el-table-column prop="dah" label="档案号"></el-table-column>
                    <el-table-column prop="bdcqz" label="不动产权证"></el-table-column>
                    <el-table-column prop="zddm" label="宗地代码"></el-table-column>
                    <el-table-column prop="qlr" label="权利人"></el-table-column>
                    <el-table-column prop="zl" label="坐落"></el-table-column>
                    <el-table-column prop="tdzh" label="土地证号"></el-table-column>
                    <el-table-column prop="tdpfh" label="土地批复号"></el-table-column>
                    <el-table-column prop="qllx" label="权利类型"></el-table-column>
                    <el-table-column prop="qlxz" label="权利性质"></el-table-column>
                    <el-table-column prop="yt" label="用途"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row, scope.$index)" type="text" size="small"
                                style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row, scope.$index)" type="text" size="small"
                                style="color:#F56C6C">隐藏</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-dialog title="修改宗地属性" :visible.sync="editModal" width="1200px" :close-on-click-modal="false" top="20px"
            @closed="dialogClosed">
            <div style="overflow: hidden;position: relative;width:100%;height:310px;background-color:#F8F8F8;margin-bottom: 20px;"
                v-if="editModal">
                <div id="mapbox" style="width:100%;height:100%;"></div>
                <div style="position: absolute;right: 5px;top: 5px;z-index: 22;">
                    <el-checkbox v-model="fgsShow" @change="mapLayersShow">分公司</el-checkbox>
                    <el-checkbox v-model="ktjShow" @change="mapLayersShow">勘探局</el-checkbox>
                    <el-checkbox v-model="drawShow" @change="mapLayersShow">当前宗地</el-checkbox>
                    <el-button type="primary" icon="el-icon-location-information" circle @click="positionMapToCenter"
                        title="定位" size="mini"></el-button>
                    <el-button type="success" icon="el-icon-refresh" circle @click="refreshMapLayers" title="刷新"
                        size="mini"></el-button>
                </div>
            </div>

            <el-form :model="postInfo" status-icon ref="eForm" label-width="120px" class="demo-uForm" v-if="nowDataItem"
                style="overflow: hidden;">
                <el-form-item label="名称" style="width:50%;float:left;">
                    <el-input v-model="postInfo.mc" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="宗地名称" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zdmc" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="档案号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.dah" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="不动产权证" style="width:50%;float:left;">
                    <el-input v-model="postInfo.bdcqz" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="权利人" style="width:100%;float:left;">
                    <el-radio v-if="nowDataItem.shpType == '1'" v-model="postInfo.qlr"
                        label="中国石油天然气股份有限公司辽河油田分公司">中国石油天然气股份有限公司辽河油田分公司</el-radio>
                    <el-radio v-if="nowDataItem.shpType == '2'" v-model="postInfo.qlr"
                        label="辽河石油勘探局有限公司">辽河石油勘探局有限公司</el-radio>
                    <el-radio v-model="postInfo.qlr" label="">无（选择无之后，此宗地图形不会在地图上显示）</el-radio>
                </el-form-item>
                <el-form-item label="宗地代码" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zddm" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="坐落" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zl" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="土地证号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.tdzh" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="土地批复号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.tdpfh" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="权利类型" style="width:50%;float:left;">
                    <el-select v-model="postInfo.qllx" placeholder="请选择权利类型" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicqllx" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权利性质" style="width:50%;float:left;">
                    <el-select v-model="postInfo.qlxz" placeholder="请选择权利性质" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicqlxz" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用途" style="width:50%;float:left;">
                    <el-select v-model="postInfo.yt" placeholder="请选择用途" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicyt" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:50%;float:left;">
                    <el-button type="primary" @click="submitEditForm()">保存</el-button>
                    <el-button @click="editModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="新增宗地" :visible.sync="addModal" width="1200px" :close-on-click-modal="false" top="20px"
            @closed="dialogClosed">
            <div style="overflow: hidden;position: relative;width:100%;height:300px;background-color:#F8F8F8;margin-bottom: 20px;"
                v-if="addModal">
                <div id="mapbox" style="width:100%;height:100%;"></div>
                <div style="position: absolute;right: 5px;top: 5px;z-index: 22;">
                    <el-checkbox v-model="fgsShow" @change="mapLayersShow">分公司</el-checkbox>
                    <el-checkbox v-model="ktjShow" @change="mapLayersShow">勘探局</el-checkbox>
                    <el-checkbox v-model="drawShow" @change="mapLayersShow">当前宗地</el-checkbox>
                    <el-button type="primary" icon="el-icon-location-information" circle @click="positionMapToCenter"
                        title="定位" size="mini"></el-button>
                    <el-button type="success" icon="el-icon-refresh" circle @click="refreshMapLayers" title="刷新"
                        size="mini"></el-button>
                </div>
            </div>

            <el-form :model="postInfo" status-icon ref="eForm" label-width="120px" class="demo-uForm"
                style="overflow: hidden;">
                <el-form-item label="坐标文件" style="width:100%;float:left;">
                    <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="uploadFunB"
                        :before-upload="beforeAvatarUploadB" style="float:left;">
                        <p v-if="chooseTxtFile">{{ txtFileName }}</p>
                        <el-button type="primary" size="mini" v-else>点击选择文件</el-button>
                    </el-upload>
                    <p :style="{ color: chooseTxtFile ? '#67C23A' : '#F56C6C' }"
                        style="font-size: 12px;margin-left: 20px;float:left;">{{
                            txtErrorMsg }}</p>
                </el-form-item>
                <el-form-item label="权利人" style="width:100%;float:left;">
                    <el-radio v-model="postInfo.qlr" label="中国石油天然气股份有限公司辽河油田分公司">中国石油天然气股份有限公司辽河油田分公司</el-radio>
                    <el-radio v-model="postInfo.qlr" label="辽河石油勘探局有限公司">辽河石油勘探局有限公司</el-radio>
                </el-form-item>
                <el-form-item label="名称" style="width:50%;float:left;">
                    <el-input v-model="postInfo.mc" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="宗地名称" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zdmc" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="档案号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.dah" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="不动产权证" style="width:50%;float:left;">
                    <el-input v-model="postInfo.bdcqz" autocomplete="off" clearable></el-input>
                </el-form-item>

                <el-form-item label="宗地代码" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zddm" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="坐落" style="width:50%;float:left;">
                    <el-input v-model="postInfo.zl" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="土地证号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.tdzh" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="土地批复号" style="width:50%;float:left;">
                    <el-input v-model="postInfo.tdpfh" autocomplete="off" clearable></el-input>
                </el-form-item>
                <el-form-item label="权利类型" style="width:50%;float:left;">
                    <el-select v-model="postInfo.qllx" placeholder="请选择权利类型" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicqllx" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权利性质" style="width:50%;float:left;">
                    <el-select v-model="postInfo.qlxz" placeholder="请选择权利性质" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicqlxz" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用途" style="width:50%;float:left;">
                    <el-select v-model="postInfo.yt" placeholder="请选择用途" filterable style="width:100%;" clearable>
                        <el-option v-for="item in dicyt" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width:50%;float:left;">
                    <el-button type="primary" @click="submitAddForm()">保存</el-button>
                    <el-button @click="editModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="地块信息查询结果" :visible.sync="queryModal" width="1160px" :close-on-click-modal="false" top="80px">
            <el-table :data="queryLandArr" stripe style="width: 100%">
                <el-table-column prop="id" label="id"></el-table-column>
                <el-table-column prop="名称" label="名称"></el-table-column>
                <el-table-column prop="不动产权证" label="不动产权证"></el-table-column>
                <el-table-column prop="土地批复号" label="土地批复号"></el-table-column>
                <el-table-column prop="土地证号" label="土地证号"></el-table-column>
                <el-table-column prop="坐落" label="坐落"></el-table-column>
                <el-table-column prop="宗地代码" label="宗地代码"></el-table-column>
                <el-table-column prop="宗地名称" label="宗地名称"></el-table-column>
                <el-table-column prop="序号" label="序号"></el-table-column>
                <el-table-column prop="权利人" label="权利人"></el-table-column>
                <el-table-column prop="权利性质" label="权利性质"></el-table-column>
                <el-table-column prop="权利类型" label="权利类型"></el-table-column>
                <el-table-column prop="档案号" label="档案号"></el-table-column>
                <el-table-column prop="用途" label="用途"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style } from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
import * as turf from '@turf/turf';
export default {
    name: "shp",
    data() {
        return {
            canDo: true,
            shpType: "1",
            attributeName: "Id",
            searchContent: "",

            dataList: [],
            postList: [],

            nowDataItem: null,

            editModal: false,
            addModal: false,
            postInfo: {},

            dicgyqk: [],
            dicqllx: [],
            dicqlxz: [],
            dicyt: [],

            map: null,
            mapCenter:null,
            view:null,
            fgsSource:null,
            fgsLayer:null,
            ktjSource:null,
            ktjLayer:null,
            drawSource:null,
            drawLayer:null,
            fgsShow:true,
            ktjShow:true,
            drawShow:true,

            txtErrorMsg:"",
            txtFile:null,
            txtFileSrc:"",
            txtFileId:"",
            txtFileName:"",
            chooseTxtFile:false,
            isCreateTxt:false,

            queryLandArr:[],
            queryModal:false,
        };
    },
    mounted() {
        this.getDisList()
    },
    methods: {
        getDisList() {
            this.$http.post("Dic/GetDataList", {})
                .then((response) => {
                    if (response.data.SUCCESS) {
                        var list = response.data.DATA
                        for (var i = 0; i < list.length; i++) {
                            var d = list[i]
                            if (d.Type == 1) {
                                this.dicgyqk.push(d.Name)
                            } else if (d.Type == 2) {
                                this.dicqllx.push(d.Name)
                            } else if (d.Type == 3) {
                                this.dicqlxz.push(d.Name)
                            } else if (d.Type == 4) {
                                this.dicyt.push(d.Name)
                            }
                        }
                    }
                })
        },
        getShpCodeByType() {
            var code = "3"
            if (this.shpType == "2") {
                code = "4"
            }
            return code
        },
        getPostList() {
            this.showLoading("请稍后")
            var code = this.getShpCodeByType()
            this.$wmsAxios.get("gdata/shp/queryshpdata/" + code + "/" + this.attributeName + "/" + this.searchContent, {})
                .then((response) => {
                    this.hideLoading()
                    this.postList = []
                    this.dataList = []
                    if (typeof response.data === 'string') {
                        var qd = JSON.parse(response.data)
                        for (var i = 0; i < qd.features.length; i++) {
                            var d = qd.features[i]
                            d.shpType = this.shpType
                            this.dataList.push(d)
                            this.postList.push(d.properties)
                        }
                        console.log(this.dataList)
                        console.log(this.postList)
                    }
                })
                .catch((error) => {
                    this.hideLoading()
                    this.postList = []
                    this.dataList = []
                    this.$message.error(error)
                });
        },
        searchPost() {
            if (this.shpType == "") {
                this.$message.error("请选择权利人")
            } else if (this.attributeName == "") {
                this.$message.error("请选择查询字段")
            } else if (this.searchContent == "") {
                this.$message.error("请输入查询内容")
            } else {
                this.getPostList()
            }
        },
        mapLayersShow(){
            if(this.fgsLayer){
                this.fgsLayer.setVisible(this.fgsShow)
            }
            if(this.ktjLayer){
                this.ktjLayer.setVisible(this.ktjShow)
            }
            if(this.drawLayer){
                this.drawLayer.setVisible(this.drawShow)
            }
        },
        initMap(from) {
            proj4.defs(
                "EPSG:4529",
                "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
            )
            window.ol.proj.proj4.register(proj4);
            register(proj4);
            this.mapCenter=[121.9939703484, 41.1335237089]
            this.view = new View({
                center: [121.9939703484, 41.1335237089],
                projection: 'EPSG:4326',
                zoom: 10,
            })
            this.fgsSource = new ImageWMS({
                url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
                params: {
                    MAP: '/etc/mapserver/wms.map',
                    LAYERS: 'fgs',
                    transparent: "true"
                },
                serverType: 'mapserver'
            })
            this.fgsLayer = new ImageLayer({
                name: "分公司",
                source: this.fgsSource,
                visible:this.fgsShow,
            }),
            this.ktjSource = new ImageWMS({
                url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
                params: {
                    MAP: '/etc/mapserver/wms.map',
                    LAYERS: 'ktj',
                    transparent: "true"
                },
                serverType: 'mapserver'
            })
            this.ktjLayer = new ImageLayer({
                        name: "勘探局",
                        source: this.ktjSource,
                        visible:this.ktjShow,
                    }),
            this.map = new Map({
                zIndex: 1,
                target: 'mapbox',
                layers: [
                    this.fgsLayer,
                    this.ktjLayer
                ],
                view: this.view,
            });
            this.drawSource = new VectorSource({ wrapX: false });
            this.drawLayer = new VectorLayer({
                source: this.drawSource,
                zIndex: 100,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(0,222,255,0)"
                    }),
                    stroke: new Stroke({
                        color: "#00DEFF",
                        width: 2
                    }),
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({
                            color: "rgba(0,222,255,1)"
                        })
                    })
                }),
                visible:this.drawShow,
            });
            this.map.addLayer(this.drawLayer)
            if(from=="edit"){
                this.appendPolygonToMap(this.nowDataItem.geometry.coordinates)
            }

            this.map.on('singleclick', (event) => {
                const coordinate = event.coordinate;
                const viewResolution = this.map.getView().getResolution();
                const projection = this.map.getView().getProjection();
                this.queryLandArr=[]
                if (this.ktjLayer.getVisible()) {
                    const ktj_url = this.ktjLayer.getSource().getFeatureInfoUrl(
                        coordinate,
                        viewResolution,
                        projection,
                        {
                            'INFO_FORMAT': 'text/html',
                            'FEATURE_COUNT': 10,
                        }
                    );
                    if (ktj_url) {
                        this.getKtjInfo(ktj_url)
                    }
                }
                if (this.fgsLayer.getVisible()) {
                    const fgs_url = this.fgsLayer.getSource().getFeatureInfoUrl(
                        coordinate,
                        viewResolution,
                        projection,
                        {
                            'INFO_FORMAT': 'text/html',
                            'FEATURE_COUNT': 10,
                        }
                    );
                    if (fgs_url) {
                        this.getFgsInfo(fgs_url)
                    }
                }

            });
        },
        getFgsInfo(url){
            fetch(url)
            .then(response => response.text())
            .then(html => {
                if (html != "") {
                    var arr=[]
                    var htmls=html.split("</html>")
                    for(var i=0;i<htmls.length -1;i++){
                        var aitem =this.extractAttributesFromHTML(htmls[i])
                        arr.push(aitem[0])
                    }
                    if(arr.length>0){
                        this.queryLandArr=arr
                        this.queryModal=true
                    }
                }
            });
        },
        getKtjInfo(url){
            fetch(url)
            .then(response => response.text())
            .then(html => {
                if (html != "") {
                    var arr=[]
                    var htmls=html.split("</html>")
                    for(var i=0;i<htmls.length -1;i++){
                        var aitem =this.extractAttributesFromHTML(htmls[i])
                        arr.push(aitem[0])
                    }
                    if(arr.length>0){
                        this.queryLandArr=arr
                        this.queryModal=true
                    }
                }
            });
        },
        extractAttributesFromHTML(html) {
            // 创建一个临时的 DOM 元素来解析 HTML
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            // 找到所有的 body 元素
            const bodies = doc.querySelectorAll('body');
            console.log(bodies)
            var  result = [];
            // 遍历每个 body 元素
            bodies.forEach(body => {
                const attributes = {};
                // 找到 body 内的所有 p 元素
                const paragraphs = body.querySelectorAll('p');
                // 遍历每个 p 元素
                paragraphs.forEach(p => {
                    // 分割文本内容以获取属性名和值
                    const text = p.textContent;
                    const colonIndex = text.indexOf(':');
                    if (colonIndex !== -1) {
                        const key = text.slice(0, colonIndex).trim();
                        const value = text.slice(colonIndex + 1).trim();
                        attributes[key] = value;
                    }
                });
                result.push(attributes);
            });

            return result;
        },
        dialogClosed() {
            if(this.map){
                this.map.dispose();
                this.map = null;
                const container = document.getElementById('mapbox');
                if (container) {
                    container.innerHTML=""
                } 
            }
            this.view=null
            this.fgsSource=null
            this.fgsLayer=null
            this.ktjSource=null
            this.ktjLayer=null
            this.drawSource=null
            this.drawLayer=null
        },
        positionMapToCenter(){
            if(this.view && this.drawSource){
                var feas=this.drawSource.getFeatures()
                for (var i = 0; i < feas.length; i++) {
                    if (feas[i].get("prop") == "shpPolygon") {
                       var coord=feas[i].getGeometry().getCoordinates()
                       this.view.setCenter(coord[0][0])
                       this.view.setZoom(15)
                       break;
                    }
                }
            }
        },
        refreshMapLayers(){
            if(this.fgsSource){
                this.fgsSource.refresh();
            }
            if(this.ktjSource){
                this.ktjSource.refresh();
            }
        },
        appendPolygonToMap(zb){
            var feaGeom= new Polygon(zb)
            var ft = new Feature({
                geometry: feaGeom,
            });    
            ft.set("prop","shpPolygon")   
            this.drawSource.addFeature(ft)
            this.view.setCenter(zb[0][0])
            this.mapCenter=zb[0][0]
            this.view.setZoom(15)
        },

        editPost(item, index) {
            this.nowDataItem = this.dataList[index]
            var mc=""
            if(item.mc)  mc=item.mc
            var zdmc=""
            if(item.zdmc)  zdmc=item.zdmc
            var dah=""
            if(item.dah)  dah=item.dah
            var bdcqz=""
            if(item.bdcqz)  bdcqz=item.bdcqz
            var zddm=""
            if(item.zddm)  zddm=item.zddm
            var qlr=""
            if(item.qlr)  qlr=item.qlr
            var zl=""
            if(item.zl)  zl=item.zl
            var tdzh=""
            if(item.tdzh)  tdzh=item.tdzh
            var tdpfh=""
            if(item.tdpfh)  tdpfh=item.tdpfh
            var qllx=""
            if(item.qllx)  qllx=item.qllx
            var qlxz=""
            if(item.qlxz)  qlxz=item.qlxz
            var yt=""
            if(item.yt)  yt=item.yt


            this.postInfo = {
                mc: mc,
                zdmc: zdmc,
                dah: dah,
                bdcqz: bdcqz,
                zddm: zddm,
                qlr: qlr,
                zl: zl,
                tdzh: tdzh,
                tdpfh: tdpfh,
                qllx: qllx,
                qlxz: qlxz,
                yt: yt,
            }
            this.editModal = true
            setTimeout(() => {
                this.initMap("edit")
            }, 200);
            
        },
        submitEditForm() {
            if (this.canDo) {
                this.canDo=false
                this.showLoading("请稍后")
                var paramStr=this.nowDataItem.properties.Id+"?shptype="+this.nowDataItem.shpType+"&mc="+this.postInfo.mc+"&zdmc="+this.postInfo.zdmc+"&dah="+this.postInfo.dah+"&bdcqz="+this.postInfo.bdcqz+"&zddm="+this.postInfo.zddm+"&qlr="+this.postInfo.qlr+"&zl="+this.postInfo.zl+"&tdzh="+this.postInfo.tdzh+"&tdpfh="+this.postInfo.tdpfh+"&qllx="+this.postInfo.qllx+"&qlxz="+this.postInfo.qlxz+"&yt="+this.postInfo.yt
                console.log(paramStr)
                //更新当前数据
                this.$wmsAxios.post("gdata/shp/updatefgsktj/"+paramStr, {})
                    .then((response) => {
                        this.hideLoading()
                        this.canDo=true
                        if(response.data.message =='success'){
                            this.$message.success("已保存")
                            this.searchPost()
                            this.editModal=false
                        }else{
                            this.$message.error(response.data.message)
                        }
                    })
                    .catch((error) => {
                        this.hideLoading()
                        this.canDo=true
                        this.$message.error("请求出错")
                    });
            }
        },
        delPost(item, index){
            if (this.canDo) {
                this.nowDataItem = this.dataList[index]
                var mc=""
                if(item.mc)  mc=item.mc
                var zdmc=""
                if(item.zdmc)  zdmc=item.zdmc
                var dah=""
                if(item.dah)  dah=item.dah
                var bdcqz=""
                if(item.bdcqz)  bdcqz=item.bdcqz
                var zddm=""
                if(item.zddm)  zddm=item.zddm
                var qlr=""
                var zl=""
                if(item.zl)  zl=item.zl
                var tdzh=""
                if(item.tdzh)  tdzh=item.tdzh
                var tdpfh=""
                if(item.tdpfh)  tdpfh=item.tdpfh
                var qllx=""
                if(item.qllx)  qllx=item.qllx
                var qlxz=""
                if(item.qlxz)  qlxz=item.qlxz
                var yt=""
                if(item.yt)  yt=item.yt


                var info = {
                    mc: mc,
                    zdmc: zdmc,
                    dah: dah,
                    bdcqz: bdcqz,
                    zddm: zddm,
                    qlr: qlr,
                    zl: zl,
                    tdzh: tdzh,
                    tdpfh: tdpfh,
                    qllx: qllx,
                    qlxz: qlxz,
                    yt: yt,
                }
                this.$confirm('确定隐藏这条数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.canDo=false
                    this.showLoading("请稍后")
                    var paramStr=this.nowDataItem.properties.Id+"?shptype="+this.nowDataItem.shpType+"&mc="+info.mc+"&zdmc="+info.zdmc+"&dah="+info.dah+"&bdcqz="+info.bdcqz+"&zddm="+info.zddm+"&qlr="+info.qlr+"&zl="+info.zl+"&tdzh="+info.tdzh+"&tdpfh="+info.tdpfh+"&qllx="+info.qllx+"&qlxz="+info.qlxz+"&yt="+info.yt
                    this.$wmsAxios.post("gdata/shp/updatefgsktj/"+paramStr, {})
                        .then((response) => {
                            this.hideLoading()
                            this.canDo=true
                            if(response.data.message =='success'){
                                this.$message.success("已隐藏")
                                this.searchPost()
                            }else{
                                this.$message.error(response.data.message)
                            }
                        })
                        .catch((error) => {
                            this.hideLoading()
                            this.canDo=true
                            this.$message.error("请求出错")
                        });
                })

            }
            


        },
        sendDelete(id,type){

        },  

        addPost() {
            this.postInfo = {
                mc: "",
                zdmc: "",
                dah: "",
                bdcqz: "",
                zddm: "",
                qlr: "中国石油天然气股份有限公司辽河油田分公司",
                zl: "",
                tdzh: "",
                tdpfh: "",
                qllx: "",
                qlxz: "",
                yt: "",
            }
            this.txtFile=null
            this.txtFileSrc=""
            this.txtFileId=""
            this.txtFileName=""
            this.chooseTxtFile=false
            this.isCreateTxt=false
            this.txtErrorMsg=""
            this.addModal = true
            setTimeout(() => {
                this.initMap("add")
            }, 200);
        },
        beforeAvatarUploadB(file) {
            const isJPG = file.type === 'text/plain';
            if (!isJPG) {
                this.$message.error('坐标文件只能是 TXT  格式!');
            }else{
                this.showLoading("正在检查文件...")
                var _URL = window.URL || window.webkitURL;
                this.txtFileSrc = _URL.createObjectURL(file);
                this.chooseTxtFile=true
                this.txtFile = file
                this.txtFileName=file.name
            }
            return isJPG;
        },
        uploadFunB(file){
            this.readFile(file.file,(data)=>{
                var arr=this.getZbByFileStr(data);
                this.hideLoading()
                if(arr.length>0){
                    this.txtErrorMsg="检查通过，可以进行提交"
                    this.loadTxtFileDoms(arr)
                }else{
                    this.txtErrorMsg='文件坐标格式有误，请重新上传'
                    this.txtFile=null
                    this.txtFileSrc=""
                    this.txtFileId=""
                    this.txtFileName=""
                    this.chooseTxtFile=false
                }
            },()=>{
                this.txtErrorMsg='解析文件出错，请重新上传'
                this.txtFile=null
                this.txtFileSrc=""
                this.txtFileId=""
                this.txtFileName=""
                this.chooseTxtFile=false
            })
        },
        readFile(file,sc,ec) {
            //支持chrome IE10
            if (window.FileReader) {
                var reader = new FileReader();
                reader.onload = function() {
                    sc(this.result);
                };
                reader.readAsText(file);
            }
            //支持IE 7 8 9 10
            else if (typeof window.ActiveXObject != 'undefined'){
                var xmlDoc;
                xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                xmlDoc.async = false;
                xmlDoc.load(input.value);
                sc(xmlDoc);
            }
            //支持FF
            else if (document.implementation && document.implementation.createDocument) {
                var xmlDoc;
                xmlDoc = document.implementation.createDocument("", "", null);
                xmlDoc.async = false;
                xmlDoc.load(input.value);
                sc(xmlDoc);
            } else {
                ec();
            }
        },
        getZbByFileStr(str){
            var arrs=[]
            var zbstr=str.replace(/\s+/g, '')
            var zbs=[]
            if(zbstr.indexOf("Z")!= -1){
                zbs= zbstr.split("Z")
            }else{
                zbs.push(zbstr)
            }

            for(var i=0;i<zbs.length;i++){
                var mian=zbs[i]
                var mianArr=[]
                if(mian.indexOf("J")!=-1){
                    var dianArr=mian.split("J")
                    for(var j=0;j<dianArr.length;j++){
                        var dian=dianArr[j]
                        var dianDatas=[]
                        if(dian!=""&&dian.indexOf(",")!=-1){
                            var diansZbs=dian.split(",")
                            for(var k=0;k<diansZbs.length;k++){
                                var dianZbItem=diansZbs[k]
                                if(dianZbItem.length>=7 || dianZbItem.indexOf(".")!=-1){
                                    dianDatas.push(parseFloat(dianZbItem))
                                }
                            }
                            if(dianDatas.length==2){
                                mianArr.push(dianDatas)
                            }
                        } 
                    }
                }
                if(mianArr.length>=4){
                    if(mianArr[0][0] != mianArr[mianArr.length-1][0] || mianArr[0][1] != mianArr[mianArr.length-1][1]){
                        mianArr.push(mianArr[0])
                    }
                    arrs.push(mianArr)
                }
            }
            return arrs
        },
        loadTxtFileDoms(arr){
            var arrItem=this.transformXY(arr)
            // if(arrItem.a.length==1){
            //     var areaGeom= new window.ol.geom.Polygon(arrItem.a)
            //     this.choooseTxtArea=areaGeom.getArea()
            // }else{
            //     var independentPolygons=this.getIndependentPolygons(arrItem.a)
            //     var sarea=0
            //     for(var i=0;i<independentPolygons.length;i++){
            //         var inditem= independentPolygons[i]
            //         let indGeom=new window.ol.geom.Polygon(inditem)
            //         //console.log(indGeom.getArea())
            //         sarea+=indGeom.getArea()
            //     }
            //     this.choooseTxtArea=sarea
            // }
            this.clearDrawPolygons()
            var newZbs=[]
            for(var z=0;z<arrItem.b.length;z++){
                var zbz=arrItem.b[z]
                var nzbz=[]
                for(var k=0;k<zbz.length;k++){
                    let zbr=window.ol.proj.transform(zbz[k], 'EPSG:3857', 'EPSG:4326');
                    nzbz.push(zbr)
                }
                newZbs.push(nzbz)
            }
            var feaGeom= new Polygon(newZbs)
            var ft = new Feature({
                geometry: feaGeom,
            });
            ft.set("prop","shpPolygon")
            this.drawSource.addFeature(ft)
            this.view.setCenter(newZbs[0][0])
            this.view.setZoom(15)
        },
        getIndependentPolygons(arrlist){
            var polygonList=[]
            var outPolygon=turf.polygon([arrlist[0]])
            var onePolygon=[arrlist[0]]
            for(var i=1;i<arrlist.length;i++){
                var pitem=arrlist[i]
                var titem=turf.polygon([pitem])
                let f=turf.booleanContains(outPolygon,titem)
                if(f){
                    onePolygon.push(pitem)
                    if((i+1)==arrlist.length){
                        polygonList.push(onePolygon)
                    }
                }else{
                    polygonList.push(onePolygon)
                    if((i+1)==arrlist.length){
                        polygonList.push([pitem])
                    }else{
                        outPolygon=turf.polygon([pitem])
                        onePolygon=[pitem]
                    }
                }
            }
            return polygonList
        },
        transformXY(olist){
            var arr={
                a:[],
                b:[],
            }
            for(var k=0;k<olist.length;k++){
                var list =olist[k]
                var l={a:[],b:[],}
                for(var i=0;i<list.length;i++){
                    var arritem=[list[i][1],list[i][0]]
                    l.a.push(arritem)
                    var lonlat_3857 = olProj.transform(arritem, 'EPSG:4529','EPSG:3857');
                    l.b.push(lonlat_3857)
                }
                arr.a.push(l.a)
                arr.b.push(l.b)
            }
            
            return arr
        },
        clearDrawPolygons(){
            if(this.drawSource){
                this.drawSource.clear()
            }
        },
        submitAddForm() {
            if(this.canDo && this.chooseTxtFile && this.txtFile){
                this.canDo =false
                this.showLoading("请稍后...")
                if(this.isCreateTxt){
                    this.updateCreateInfo()
                }else{
                    var createurl=""
                    if(this.postInfo.qlr =="中国石油天然气股份有限公司辽河油田分公司"){
                        createurl="gdata/shp/createfgs/"
                    }else if(this.postInfo.qlr =="辽河石油勘探局有限公司"){
                        createurl="gdata/shp/createktj/"
                    }

                    var fd = new FormData();
                    fd.append("file", this.txtFile);
                    //上传文件
                    this.$wmsUpload.post(createurl, fd)
                    .then((response) => {
                        this.hideLoading()
                        this.canDo=true
                        if(response.data.id){
                            this.txtFileId=parseInt(response.data.id)
                            this.isCreateTxt=true
                            this.updateCreateInfo()
                        }else{
                            this.$message.error("图形创建失败")
                        }
                    }) 
                    .catch((error) => {
                        this.hideLoading()
                        this.canDo=true
                        this.$message.error("图形创建失败")
                    });
                }
            }
        },
        updateCreateInfo(){
            var shpType="1"
            if(this.postInfo.qlr == '中国石油天然气股份有限公司辽河油田分公司'){
                shpType="1"
            }else if(this.postInfo.qlr == '辽河石油勘探局有限公司'){
                shpType="2"
            }
            var paramStr=this.txtFileId+"?shptype="+shpType+"&mc="+this.postInfo.mc+"&zdmc="+this.postInfo.zdmc+"&dah="+this.postInfo.dah+"&bdcqz="+this.postInfo.bdcqz+"&zddm="+this.postInfo.zddm+"&qlr="+this.postInfo.qlr+"&zl="+this.postInfo.zl+"&tdzh="+this.postInfo.tdzh+"&tdpfh="+this.postInfo.tdpfh+"&qllx="+this.postInfo.qllx+"&qlxz="+this.postInfo.qlxz+"&yt="+this.postInfo.yt
                this.$wmsAxios.post("gdata/shp/updatefgsktj/"+paramStr, {})
                    .then((response) => {
                        this.hideLoading()
                        this.canDo=true
                        if(response.data.message =='success'){
                            this.$message.success("已保存")
                        }else{
                            this.$message.error(response.data.message)
                        }
                    })
                    .catch((error) => {
                        this.hideLoading()
                        this.canDo=true
                        this.$message.error("数据更新失败！")
                    });
        },

    },
    computed: {
    },
    watch: {
    },
};
</script>