<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">人员新设备登录审核</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="shzt" placeholder="审核状态"  filterable  clearable class="iw">
                        <el-option label="待审核" value="0" ></el-option>
                        <el-option label="通过" value="1" ></el-option>
                        <el-option label="不通过" value="2" ></el-option>
                </el-select>
                <el-select v-model="unitid" placeholder="选择作业单位"  filterable  clearable class="iw" @change="changeUnit">
                        <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-select v-model="userid" placeholder="选择作业人员"  filterable  clearable class="iw" v-if="unitid!=''">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="WorkUnitName" label="单位"></el-table-column>
                    <el-table-column prop="Name" label="人员"></el-table-column>
                    <el-table-column prop="STATUS" label="审核状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS== '0'" style="color:#ff9900">待审核</span>
                            <span v-else-if="scope.row.STATUS== '1'" style="color:#19be6b">通过</span>
                            <span v-else-if="scope.row.STATUS== '2'" style="color:#ed4014">不通过</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MAC" label="MAC地址"></el-table-column>
                    <el-table-column prop="PHONEMODEL" label="设备型号"></el-table-column>
                    <el-table-column prop="CreateTime" label="提交时间" width="180"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="auditing(scope.row)" type="text" size="small" style="color:#ff9900" v-if="scope.row.STATUS=='0'">审核</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="auditingTitle" :visible.sync="auditingModal" width="800"  :close-on-click-modal="false">
            <div style="overflow:hidden;">
                <el-radio v-model="auditingRadio" label="1">通过</el-radio>
                <el-radio v-model="auditingRadio" label="2">不通过</el-radio>
            </div> 
            <div style="margin: 20px 0;">
                <el-button @click="auditingModal = false" class="fr ml20">取消</el-button>
                <el-button type="primary" @click="sendAuditing()" class="fr ml20">提交</el-button>
            </div>
            <div style="height:20px"></div>
        </el-dialog>

    </div>
</template>
<script>
export default {
  name: "WorkUserMacList",
  data() {
    return {
        canDo:true,
        showPagination:false,

        userid:"",
        shzt:"",

        unitid:"",
        unitList:[],
        userList:[],
        ejzydwList:[],


        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,



        auditingModal:false,
        auditingTitle:"",
        auditingUserInfo:null,
        auditingRadio:"1",
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.userid=getQueryData.userSnowID
        this.shzt=getQueryData.Status
    }
    this.loadItem=null
    this.getUnitList()
    
  },
  methods: {
    getUnitList(){
        this.$http.post("WorkUnit/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        if(d.DWJB==1){
                            this.unitList.push(d)
                        }else if(d.DWJB==2){
                            this.ejzydwList.push(d)
                        }
                    }
                    this.getPostList()
                }
            })
    },
    changeUnit(e){
        this.userid=""
        this.userList=[]
        if(e!=""){
            this.getUserList(e)
        }
    },
    getUserList(id){
        this.$http.post("WorkUser/GetDataList", {parentSnowID:id,IsQueryChild:"1",})
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var dwjb=""
        if(this.unitid!="" && this.userid==""){
            dwjb="1"
        }
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            userSnowID:this.userid,
            Status:this.shzt,
            workunitsnowid:this.unitid,
            DWJB:dwjb,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Mac/GetMacList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        var pdwname=this.getParentUnitName(d)
                        if(pdwname!=""){
                            d.WorkUnitName=pdwname
                        }
                        nl.push(d)
                    }
                    this.postList=nl
                    this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    getParentUnitName(item){
        var parentdwName=""
        var nowName=item.WorkUnitName
        for(var i=0;i<this.ejzydwList.length;i++){
            if(this.ejzydwList[i].DISPLAYNAME == nowName){
                var pid=this.ejzydwList[i].ParentSnowID
                for(var j=0;j<this.unitList.length;j++){
                    if(this.unitList[j].SNOWID == pid){
                        parentdwName=this.unitList[j].DISPLAYNAME
                        break;
                    }
                }
                break;
            }
        }
        return parentdwName
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    auditing(item){
        this.auditingUserInfo=item
        this.auditingModal=true
        this.auditingTitle=item.Name+"审核"
        this.auditingRadio="1"
    },
    sendAuditing(){
        var params={
            macSnowID: this.auditingUserInfo.SNOWID,
            status: this.auditingRadio,
        }
        this.$http.post("SysUser/SetMacValid", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核完成")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
             });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem{float: right;}
.showFieldTtem{float: right;margin-right: 20px;}
.itemOut{overflow: hidden;background-color: #F8F8F9;padding: 10px 20px 0 20px;margin-bottom:10px;border-radius:4px;}
.halfItem{width: 50%;float:left;margin-bottom: 25px!important;}
.itemBox{overflow: hidden;width:100%;}
.itemTit{overflow: hidden;}
.itemTit p{float:left;}

.moreRow{background-color: #F8F8F9;overflow: hidden;padding: 12px;}
.moreRow .moreRowTit{margin:10px 0;font-size: 16px;}
.moreRow .moreRowTable{width: 100%;}

</style>