<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">井口清查</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="jcmc" placeholder="请输入井场名称" class="iw"></el-input>
                <!-- <el-input v-model="jkh" placeholder="请输入井口号" class="iw"></el-input> -->
                <el-select v-model="ejdw" placeholder="选择提二级单位" filterable clearable class="iw">
                    <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
                <el-select v-model="unitId" placeholder="选择清查单位" filterable clearable class="iw" @change="unitChange">
                    <el-option v-for="item in unitList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="userid" placeholder="选择提交人" filterable clearable class="iw">
                    <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="ss" placeholder="选择是否上市" filterable clearable class="iw">
                    <el-option label="上市" value="1"></el-option>
                    <el-option label="未上市" value="0"></el-option>
                </el-select>
                <el-select v-model="qz" placeholder="选择是否侵占" filterable clearable class="iw">
                    <el-option label="侵占" value="1"></el-option>
                    <el-option label="未侵占" value="0"></el-option>
                </el-select>
                <el-select v-model="dm" placeholder="选择周边地貌" filterable clearable class="iw">
                    <el-option v-for="item in dmList" :key="item.SnowID" :label="item.Name"
                        :value="item.SnowID"></el-option>
                </el-select>
                <el-select v-model="isError" placeholder="选择异常状态" filterable clearable class="iw" @change="unitChange">
                    <el-option label="全部异常状态" value=""></el-option>
                    <el-option label="正常" value="0"></el-option>
                    <el-option label="异常" value="1"></el-option>
                </el-select>
                <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" style="margin-top: 10px;"></el-date-picker>
                <el-button type="primary" @click="searchPost" style="margin-left: 20px;">查询</el-button>
                <el-button type="warning" @click="exportPost">导出</el-button>
            </div>
            <div class="views-main-body" style="top:85px;">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="WELLFACTORYNAME" label="井场名称"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="WellCount" label="井口数量"></el-table-column>
                    <el-table-column prop="WellName" label="井口名称"></el-table-column>
                    <el-table-column prop="IsError" label="异常状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.IsError == 1" style="color:#909399">异常</span>
                            <span v-else-if="scope.row.IsError == 0" style="color:#67C23A">正常</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="IsSH" label="是否上市">
                        <template slot-scope="scope">
                            <span v-if="scope.row.IsSH == 0" style="color:#909399">未上市</span>
                            <span v-else-if="scope.row.IsSH == 1" style="color:#67C23A">已上市</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="IsOccupy" label="是否侵占">
                        <template slot-scope="scope">
                            <span v-if="scope.row.IsOccupy == 0" style="color:#67C23A">未侵占</span>
                            <span v-else-if="scope.row.IsOccupy == 1" style="color:#F56C6C">已侵占</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="UtilizationName" label="使用情况"></el-table-column>
                    <el-table-column prop="LandformsName" label="周边地貌"></el-table-column>
                    <el-table-column prop="CheckUnitName" label="清查单位"></el-table-column>
                    <el-table-column prop="CreatorName" label="清查人员"></el-table-column>
                    <el-table-column prop="CREATETIME" label="提交时间"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="detailPost(scope.row)" type="text" size="small"
                                style="color:#409EFF">位置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                    :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="清查位置" :visible.sync="postModal" width="800px" :close-on-click-modal="false" top="80px">
            <div id="mapbox" style="width: 100%;height: 500px;"></div>
            <div style="overflow: hidden;margin-top: 10px;">
                <el-image v-for="(img, index) in prevImgs" :key="img.SnowID"
                    style="width: 100px; height: 100px;margin-right: 10px;" :src="img.Path"
                    :preview-src-list="prevAllImgs" @mouseenter="handleMouseEnter(index)"
                    @mouseleave="handleMouseLeave(index)"></el-image>
            </div>
        </el-dialog>

        <el-dialog title="导出清查记录表" :visible.sync="exportModal" width="1200px" :close-on-click-modal="false">
            <el-form status-icon label-width="200px" class="demo-uForm">
                <el-form-item label="数据来源">
                    <el-radio v-model="exportSource" label="1">清查单位</el-radio>
                    <el-radio v-model="exportSource" label="2">二级单位</el-radio>
                    <el-radio v-model="exportSource" label="3">所有单位</el-radio>
                </el-form-item>
                <el-form-item label="清查单位" v-if="exportSource == '1'">
                    <el-checkbox-group v-model="checkUnits">
                        <el-checkbox v-for="item in unitList" :label="item.SNOWID" :key="item.SNOWID">{{
                            item.DISPLAYNAME
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="二级单位" v-if="exportSource == '2'">
                    <el-checkbox-group v-model="checkUnits">
                        <el-checkbox v-for="item in ejdwList" :label="item" :key="item">{{
                            item
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <el-button :type="exportStatus ? 'danger' : 'warning'" @click="submitExport">{{ exportStatus ?
                        '导出中，点击停止' :
                        '确定导出' }}</el-button>
                    <el-button @click="fallEditModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style ,Icon} from "ol/style";
import XYZ from "ol/source/XYZ";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
import * as turf from '@turf/turf';
export default {
  name: "RegionManage",
  data() {
    return {
        canDo:true,
        showPagination:false,
        userList:[],
        userid:"",

        ejdw:"",
        ejdwList:[],
        jcmc:"",
        jkh:"",
        times:[],
        isError:"",

        unitId:"",
        unitList:[],    

        dmList:[],
        dm:"",
        ss:"",
        qz:"",

        qk:"",
        qkList:[],

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        detailItem:null,
        postModal:false,

        map:null,
        view:null,
        drawSource:null,
        drawLayer:null,
        kqLayer:null,
        kqSource:null,

        prevImgs:[],
        prevAllImgs:[],


        exportSource:"1",
        exportModal:false,
        checkUnits:[],
        exportTimer:"",
        exportStatus:false,
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.userid=getQueryData.userSnowID
        this.ejdw=getQueryData.ejdw
        this.jcmc=getQueryData.wellFactoryName
        this.jkh=getQueryData.wellNO
        this.dm=getQueryData.landformsSnowID
        this.ss=getQueryData.isSH
        this.qz=getQueryData.isOccupy
    }
    this.loadItem=null
    this.getUnitList()
    this.getUserList("")
    this.getDMQKList()
    this.getEjdwList()
    this.getPostList()
  },
  methods: {
    unitChange(e){
        this.userList=[]
        this.getUserList(e)
    },
    getUnitList() {
            this.$http.post("CheckUnit/GetDataList", {})
                .then((response) => {
                    if (response.data.SUCCESS) {
                        this.unitList = response.data.DATA
                    }
                })
        },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getUserList(id){
        this.$http.post("CheckUser/GetDataList", {checkunitsnowid:id})
            .then((response) => {
                this.userList=response.data.DATA
            })
    },
    getDMQKList(){
        this.$http.post("Dic/GetDataList", {type:"5"})
            .then((response) => {
                this.dmList=response.data.DATA
            })
            this.$http.post("Dic/GetDataList", {type:"6"})
            .then((response) => {
                this.qkList=response.data.DATA
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var st=""
        var et=""
        if(this.times && this.times.length == 2){
            st =this.comjs.getDateStrByDate(this.times[0])
            et =this.comjs.getDateStrByDate(this.times[1])
        }
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            userSnowID:this.userid,
            ejdw:this.ejdw,
            wellFactoryName:this.jcmc,
            wellNO:this.jkh,
            startTime:st,
            endTime:et,
            landformsSnowID:this.dm,
            isSH:this.ss,
            isOccupy:this.qz,
            IsError:this.isError,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("CheckInfo/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    var list =response.data.DATA
                    var nl=[]
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.WellName=""
                        for(var j=0;j<d.WellNameList.length;j++){
                            if(d.WellName == ""){
                                d.WellName = d.WellNameList[j]
                            }else{
                                d.WellName += "," + d.WellNameList[j]
                            }
                        }
                        nl.push(d)
                    }
                    console.log(nl)
                    this.postList=nl
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },
    detailPost(item){
        if(item.LAT && item.LAT!= 0 && item.LON && item.LON!=0){
            this.detailItem=item
            this.postModal=true
            setTimeout(()=>{
                if(!this.map){
                    this.initMap()
                }else{
                    this.drawSource.clear()
                }
                this.setWellMapInfo(item)
            },500)
        }else{
            this.$message.error("暂无位置信息")
        }
        
    },
    setWellMapInfo(item){
        var center=[item.LON,item.LAT]

        this.view.setCenter(center)
        var style =new Style({
            image: new Icon({
                    anchor: [0.5, 1],
                    src: require('../../../public/statics/img/position.png'),
                    scale: 0.6,
                })
            })

        var feaGeom= new Point(center)
        var ft = new Feature({
            geometry: feaGeom,
        });    
        ft.setStyle(style)  
        this.drawSource.addFeature(ft)

        if(item.ZBStr && item.ZBStr!=""){
            var zb=item.ZBStr
            var clist=[]
            if(zb.indexOf("|")!= -1){
                clist=zb.split("|")
            }else{
                clist.push(zb)
            }
            var mcenter=[]
            for(var i=0;i<clist.length;i++){
                var pitems=[]
                var spitem=clist[i].split(";")
                for(var j=0;j<spitem.length;j++){
                    if(spitem[j]!="" && parseFloat(spitem[j].split(",")[0]) != 0 && parseFloat(spitem[j].split(",")[1])!= 0){
                        var ppitem=[parseFloat(spitem[j].split(",")[0]),parseFloat(spitem[j].split(",")[1])]
                        if(mcenter.length==0){
                            mcenter=ppitem
                        }
                        pitems.push(ppitem)
                    } 
                }
                if(pitems.length>0){
                    var feaGeomPolygon= new Polygon([pitems])
                    var ftPolygon = new Feature({
                        geometry: feaGeomPolygon,
                    });    
                    this.drawSource.addFeature(ftPolygon)
                }
            }
        }


        var files=item.Files
        this.prevImgs=files
        var  aplsit=[]
        for(var i=0;i<files.length;i++){
            var fd=files[i]
            aplsit.push(fd.Path)
            if(fd.Lon && fd.Lon!=0 && fd.Lat && fd.Lat!=0){

                var rat = 0
                if (fd.Direction) {
                    rat = fd.Direction * (Math.PI / 180)
                }
                var arrStyle=new Style({
                    image: new Icon({
                        anchor: [0.5, 1],
                        src: require('../../../public/statics/img/arrorA.png'),
                        scale: 0.6,
                        rotation: rat,
                    })
                })
                var arrGeom= new Point([fd.Lon,fd.Lat])
                var arrft = new Feature({
                    geometry: arrGeom,
                });   
                arrft.setId(fd.SnowID) 
                arrft.set("index",i)
                arrft.setStyle(arrStyle)  
                this.drawSource.addFeature(arrft)
            }
            
        }
        this.prevAllImgs=aplsit

    },
    initMap() {
            proj4.defs(
                "EPSG:4529",
                "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
            )
            window.ol.proj.proj4.register(proj4);
            register(proj4);
            this.view = new View({
                center: [121.9939703484, 41.1335237089],
                projection: 'EPSG:4326',
                zoom: 10,
                maxZoom: 18,
                minZoom: 4,
            })
            this.kqSource = new ImageWMS({
                url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
                params: {
                    MAP: '/etc/mapserver/wms.map',
                    LAYERS: 'ytdk',
                    transparent: "true"
                },
                serverType: 'mapserver'
            })
            this.kqLayer = new ImageLayer({
                name: "矿区",
                source: this.kqSource,
                zIndex:19,
            })
            //地图影像
            var mapSourceC = new XYZ({
            url:"https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
            })
            //影像标注
            var mapSourceD = new XYZ({
            url:"https://t0.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=ca74f8e21b03ab0a72cd2fb9b66aaa2b"
            })
            var mapLayerC = new TileLayer({
                source: mapSourceC,
                zIndex: 2,
            });
            var mapLayerD = new TileLayer({
                source: mapSourceD,
                zIndex: 3,
            });
            this.map = new Map({
                zIndex: 1,
                target: 'mapbox',
                layers: [
                    this.kqLayer,
                    mapLayerC,
                    mapLayerD,
                    // this.ktjLayer
                ],
                view: this.view,
            });
            this.drawSource = new VectorSource({ wrapX: false });
            this.drawLayer = new VectorLayer({
                source: this.drawSource,
                zIndex: 100,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(0,222,255,0)"
                    }),
                    stroke: new Stroke({
                        color: "#00DEFF",
                        width: 2
                    }),
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({
                            color: "rgba(0,222,255,1)"
                        })
                    })
                }),
                visible:this.drawShow,
            });
            this.map.addLayer(this.drawLayer)
    },
    handleMouseEnter(index){
        var fileItem =this.prevImgs[index]
        var  fea=this.drawSource.getFeatureById(fileItem.SnowID)
        var rat = 0
        if (fileItem.Direction) {
            rat = fileItem.Direction * (Math.PI / 180)
        }
        var arrStyle=new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: require('../../../public/statics/img/arrorB.png'),
                scale: 0.6,
                rotation: rat,
            })
        })
        fea.setStyle(arrStyle)  
    },
    handleMouseLeave(index){
        var fileItem =this.prevImgs[index]
        var  fea=this.drawSource.getFeatureById(fileItem.SnowID)
        var rat = 0
        if (fileItem.Direction) {
            rat = fileItem.Direction * (Math.PI / 180)
        }
        var arrStyle=new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: require('../../../public/statics/img/arrorA.png'),
                scale: 0.6,
                rotation: rat,
            })
        })
        fea.setStyle(arrStyle)  
    },



    exportPost(){
        this.checkUnits=[]
        this.exportModal=true
    },
    submitExport(){
        if(this.canDo){
            if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                })
               
            }else{
                // if(this.checkUnits.length===0){
                //     this.$message.error("请至少选择一个作业单位")
                // }else{
                //     this.exportTimer=new Date().getTime().toString()
                //     this.sendExport(this.exportTimer)
                // }
                this.exportTimer=new Date().getTime().toString()
                this.sendExport(this.exportTimer)
            }
            
        }

    },
    sendExport(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                checkUnitSnowIDList:[],
                ejdwList:[],
            }

            if(this.exportSource=="1"){
                params.checkUnitSnowIDList=this.checkUnits
            }else if(this.exportSource=="2"){
                params.ejdwList=this.checkUnits
            }

            this.$download.post("ExportExcel/ExportCheckInfoList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName()
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.xls')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".xls")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".xls"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                })
                .catch((error) => {
                    this.hideLoading()
                });
            }
    },
    getZydwStrById(id){
        var name=''
        for(var i=0;i<this.unitList.length;i++){
            if(this.unitList[i].SNOWID == id){
                name=this.unitList[i].DISPLAYNAME
                break;
            }
        }
        return name
    },
    getFileName(){
        var name=""
        if(this.exportSource=="1"){
            for(var i=0;i<this.checkUnits.length;i++){
                var dwname=this.getZydwStrById(this.checkUnits[i])
                if(i==0){
                    name=dwname
                }else{
                    name+="_"+dwname
                }
            }
        }else if(this.exportSource=="2"){
            for(var j=0;j<this.checkUnits.length;j++){
                if(j==0){
                    name=this.checkUnits[j]
                }else{
                    name+="_"+this.checkUnits[j]
                }
            }
        }
        if(name!=""){
            name+="_"
        }
        
        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+=""+t
        //"_"
        name+="清查数据导出"
        return name
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem {
    float: right;
}

.showFieldTtem {
    float: right;
    margin-right: 20px;
}

.itemOut {
    overflow: hidden;
    background-color: #F8F8F9;
    padding: 10px 20px 0 20px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.halfItem {
    width: 50%;
    float: left;
    margin-bottom: 25px !important;
}

.itemBox {
    overflow: hidden;
    width: 100%;
}

.itemTit {
    overflow: hidden;
}

.itemTit p {
    float: left;
}

.moreRow {
    background-color: #F8F8F9;
    overflow: hidden;
    padding: 12px;
}

.moreRow .moreRowTit {
    margin: 10px 0;
    font-size: 16px;
}

.moreRow .moreRowTable {
    width: 100%;
}
</style>